<template>
  <CommonLoading v-if="loading" />
  <form
    v-else
    id="form_trial_signup_callme"
    ref="callMeForm"
    class="ml-auto mr-auto text-center grid gap-4"
    :class="{
      'w-full md:w-96': false,
      'w-fit h-full': false,
      'w-64': true,
    }"
    @submit.prevent="submit"
  >
    <p
      v-if="false"
      class="mx-auto text-sm text-gray-800 leading-snug mb-4"
    >
      Vi ringer til dig med et uforpligtende tilbud på Mandag Morgen. Det står
      dig altså frit for, om du vil acceptere tilbuddet.
    </p>
    <p v-else class="mx-auto text-sm text-gray-800 leading-snug mb-4">
      {{ $t('trialSignup.callMeFormInfo') }}
    </p>

    <FormAlert :type="alertType" :message="alertMessage" />

    <FormInput
      ref="nameinput"
      v-model="name"
      required
      placeholder="NameHelpText"
      label="Name"
    />

    <FormInput
      ref="emailinput"
      v-model="email"
      type="email"
      required
      placeholder="Email"
      label="Email"
    />

    <FormInput
      ref="phoneinput"
      v-model="phone"
      type="tel"
      required
      placeholder="Phone"
      :pattern="config.public.site.formValidation?.phonePattern"
      label="Phone"
    />
    <FormInput
      v-if="false"
      ref="titleinput"
      v-model="title"
      required
      placeholder="JobTitle"
      label="JobTitle"
    />
    <FormInput
      v-if="false"
      ref="companyinput"
      v-model="company"
      required
      placeholder="Company"
      label="Company"
    />

    <div v-if="!true" class="flex items-start justify-start">
      <CommonPermissionBox
        :isNewsletter="false ? true : false"
        class="text-left mt-1"
        smallText
        :permissionGiven="marketingPermissionGiven"
        @update:permission="updateMarketingPermission"
      />
    </div>

    <CommonButton
      :bg="false ? 'bg-black' : 'bg-blue'"
      text="text-white"
      border="border-none"
      size=""
      class="w-full cursor-pointer py-3 px-4 article-label !font-medium"
      :class="
        false
          ? 'rounded-md transform translate-y-px'
          : 'rounded-full hover:bg-denimblue'
      "
      :disabled="!callMeForm?.checkValidity()"
    >
      {{ false ? 'Ring mig op' : $t('ads.callMe') }}
    </CommonButton>
  </form>
</template>

<script setup lang="ts">
const nuxtApp = useNuxtApp()
const route = useRoute()
const config = useRuntimeConfig()
const gtm = useGtm()
const { nwCookie } = useNwCookie()
const { setAlert, clearAlert, alertType, alertMessage } = useAlert()

const props = withDefaults(
  defineProps<{
    eventLabel?: string
    newsletter?: string | number
    requestedProduct?: string // Used for MM only, define the requested product for the marketing (will be sent in letter to marketing)
    papers?: number[] // For subscription page, when user choose several niches and click "Få et uforpligtende tilbud"
  }>(),
  {
    eventLabel: 'FreeNewsletterSignup',
    newsletter: 1, // 1 = the free frontpage newsletter.
  }
)

const name = ref('')
const email = ref('')
const phone = ref('')
const title = ref('')
const company = ref('')
const loading = ref(false)
const marketingPermissionGiven = ref(false)
const callMeForm = ref<HTMLFormElement>()

const emit = defineEmits(['complete', 'signed-up'])

async function submit() {
  loading.value = true
  clearAlert()

  try {
    // CallMe component is used on Norway price page and use old endpoint, without permission
    if (true) {
      await nuxtApp.$api.user.trialSignup({
        email: email.value,
        name: name.value,
        phone: phone.value,
        url: route.fullPath,
        title: title.value,
        jobPlace: company.value,
        products: props.requestedProduct,
      })
    } else {
      await nuxtApp.$api.leads.trialLead({
        customerName: name.value,
        customerEmail: email.value,
        customerTelephoneNumber: phone.value,
        customerCompany: company.value,
        customerJobTitle: title.value,
        papers: props.papers,
      })
    }

    emit('complete')
  } catch (error) {
    setAlert((error as { response: { data: string } })?.response?.data)
  }
  loading.value = false

  if (marketingPermissionGiven.value) {
    // Subscribe to free newsletter for mm.dk: temporary solution, until subbscriptions will be moved to Iteras
    if (false) {
      const { err } = await nuxtApp.$api.user.subscribeFreeNewsletter(
        email.value,
        FREE_NEWSLETTER_ID
      )

      if (err) {
        console.error(err.message, ': ', err.cause)
      } else {
        emit('signed-up')

        gtm?.trackEvent({
          event: 'formTracking',
          eventCategory: 'Form',
          eventAction: 'Form Completed',
          eventLabel: props.eventLabel,
        })
        nwCookie.value = true
      }
    }
    if (!true) {
      const { firstName, lastName } = splitUserName(name.value || '')
      const { err } = await nuxtApp.$api.user.addMarketingPermission({
        firstName: firstName,
        lastName: lastName ? lastName : null,
        email: email.value,
        phone: phone.value,
        company: false ? company.value : undefined,
        jobTitle: false ? title.value : undefined,
        permissionSource: 'Lead Form',
        permissionSourceUrl: window.location.href,
      })
      if (err) {
        console.error(err.message, ': ', err.cause)
      }
    }
  }
}

const updateMarketingPermission = (newValue: boolean) => {
  marketingPermissionGiven.value = newValue
}
</script>
